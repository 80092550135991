/* :root{
  --amplify-primary-color: #84bd00;
  --amplify-primary-tint: #79ad00;
  --amplify-primary-shade: #638d00;
} */

h1, h2, h3, h4, h5, h6 {
  font-weight:normal;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a, a:hover {
  color: #84bd00;
}

table th {
  text-align:inherit;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.table-responsive {
	overflow-x:auto;
	width:100%;
}

table.series-results th.place, 
table.series-results td.place,
table.series-results th.bib, 
table.series-results td.bib {
	text-align:center;
	width: 1%;
}

table.series-results th.name, 
table.series-results td.name,
table.series-results th.team, 
table.series-results td.team,
table.series-results th.className,
table.series-results td.className {
	max-width:12em;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space: nowrap;
}

table.series-results th.time, 
table.series-results td.time {
	white-space: nowrap;
	width: 1%;
}
